.form-background {
  height: auto;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 20px;
  gap: 10px;
  font-family: "Montserrat";
}
.inner-form {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.first-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-left {
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: flex-start;
}

.design-pref-label {
  width: 200px;
}
.google-map-container {
  height: 300px;
  width: auto;
}

.google-autocomplete {
  height: 50px;
  font-size: 13.5px;
  background: transparent;
  border: 1px solid #c4c4c4;
  padding-left: 15px;
  border-radius: 10px;
}

.error-message {
  color: rgb(233, 18, 18);
}

.pin-icon {
  height: 30px;
  width: 30px;
}
.pin {
  margin: -20px;
}
